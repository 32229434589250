import { httpsCallable } from 'firebase/functions'
import { functions } from '@/libs/firebase'
import i18n from '@/libs/i18n'
import store from '@/store'

const customerPortal = (to, _, next) => {
  store.commit('app/TOGGLE_LOADING', true)

  const generateStripeCustomerPortalSessionUrl = httpsCallable(functions(), 'generateStripeCustomerPortalSessionUrl')

  generateStripeCustomerPortalSessionUrl()
    .then(({ data: url }) => {
      store.dispatch('notify', {
        body: i18n.t('You will be redirected to your customer portal'),
      })

      window.location.href = url
      store.commit('app/TOGGLE_LOADING', false)
    }).catch(() => {
      store.commit('app/TOGGLE_LOADING', false)
      store.dispatch('notify', {
        body: i18n.t('Not possible to redirect you to the customer portal'),
        variant: 'danger',
      })
    })
}

/* eslint-disable import/prefer-default-export */
export { customerPortal }
